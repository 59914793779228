import jquery from '../../node_modules/jquery/dist/jquery'
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import persist from '@alpinejs/persist'
import stencil from './alpine-stencil'

window.jQuery = jquery
window.$ = jquery
Alpine.plugin(intersect)
Alpine.plugin(persist)
Alpine.plugin(stencil)
window.Alpine = Alpine
