/**
 * A custom directive for pulling in the content of a template tag.
 * The expression must evaluate to a value; it won't be treated as a string.
 * https://alpinejs.dev/advanced/extending#custom-directives
 */
export default function (Alpine) {
  Alpine.directive('stencil', (el, { modifiers, expression }, { evaluateLater, effect }) => {
    const evaluateTemplateId = evaluateLater(expression)

    effect(() => {
      evaluateTemplateId(templateId => {
      // Resolve the expression to a DOM Id and find the template
        const fragment = document.querySelector(`template[id=${templateId}]`)

        if (!fragment) {
          return
        }

        // Create a clone of the template content to prepare for DOM insertion
        const clone = document.importNode(fragment.content, true).firstElementChild

        // If modifiers were provided we will add them to the cloned SVG as classes.
        if (modifiers.length > 0) {
          clone.removeAttribute('class')
          modifiers.forEach(str => {
            clone.classList.add(str)
          })
        }

        // Insert the cloned template into the DOM tree as a child of the current element.
        el.replaceChildren(clone)
      })
    })
  })
}
